import React, { useEffect, useState, useRef } from 'react';
import {
  Table,
  Select,
  InputNumber,
  Checkbox,
  Button,
  Tooltip,
  Alert,
  message,
  Radio,
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { onSocialWorkRead } from '../../../actions';

const { Option } = Select;

const daysOfWeek = [
  { label: 'L', value: 'lu' },
  { label: 'M', value: 'ma' },
  { label: 'M', value: 'mi' },
  { label: 'J', value: 'ju' },
  { label: 'V', value: 'vi' },
  { label: 'S', value: 'sá' },
  { label: 'D', value: 'do' },
];

const SocialWorkConfigurationForm = ({ config, coverage, updateConfig }) => {
  const [socialWorksConfig, setSocialWorksConfig] = useState([]);
  const [coverageType, setCoverageType] = useState(); // Estado inicial

  const { socialWorks } = useSelector((store) => store.socialWorkData);

  const dispatch = useDispatch();

  const tableRef = useRef(null);

  useEffect(() => {
    dispatch(onSocialWorkRead());
  }, []);

  useEffect(() => {
    setSocialWorksConfig(config);
    setCoverageType(coverage);
  }, [config, coverage]);

  const handleAddRow = () => {
    setSocialWorksConfig([
      ...socialWorksConfig,
      {
        socialWork: null,
        quantity: null,
        days: ['lu', 'ma', 'mi', 'ju', 'vi'],
      },
    ]);

    setTimeout(() => scrollToBottom(), 100); // Espera a que la fila se renderice antes de hacer scroll
  };

  const scrollToBottom = () => {
    if (tableRef.current) {
      const tableBody = tableRef.current.querySelector('.ant-table-body');
      if (tableBody) {
        tableBody.scrollTop = tableBody.scrollHeight; // Desplaza hasta el final
      }
    }
  };

  const handleDeleteRow = (index) => {
    // Genera un nuevo array excluyendo el índice a eliminar
    const updatedConfig = socialWorksConfig.filter((_, i) => i !== index);

    setSocialWorksConfig(updatedConfig);
  };

  const handleChange = (index, field, value) => {
    const updatedConfig = [...socialWorksConfig];
    updatedConfig[index][field] = value;
    setSocialWorksConfig(updatedConfig);
  };

  const handleSave = async () => {
    message.loading('Guardando..', 0);

    const isValid = socialWorksConfig.every(
      (record) =>
        record.socialWork &&
        (record.exclusion || (record.days && record.days.length > 0))
    );

    try {
      if (!isValid) {
        message.error(
          'Por favor, selecciona una obra social y al menos un día para todas las filas.'
        );
        return;
      } else {
        await updateConfig(socialWorksConfig, coverageType);
        message.destroy();
      }
    } catch {
      message.error('Error, inteente nuevamente.');
    }
  };

  const columns = [
    {
      title: 'Obra Social',
      dataIndex: 'socialWork',
      key: 'socialWork',
      width: '25%',
      render: (_, record, index) => (
        <Select
          placeholder="Seleccione una obra social"
          style={{ width: '100%' }}
          value={record.socialWork}
          onChange={(value) => handleChange(index, 'socialWork', value)}
          showSearch
        >
          {socialWorks?.map((sw) => (
            <Option key={sw._id} value={sw.name}>
              {sw.name}
            </Option>
          ))}
        </Select>
      ),
    },
    {
      title: 'Max turnos x día',
      dataIndex: 'quantity',
      key: 'quantity',
      width: '25%',
      render: (_, record, index) => (
        <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
          <Select
            style={{ width: 120 }}
            defaultValue={
              record?.exclusion
                ? 'excluir'
                : record.quantity === null
                ? 'sin-limite'
                : 'limite'
            }
            onChange={(value) => {
              handleChange(index, 'exclusion', value === 'excluir');
              if (value !== 'limite') {
                handleChange(index, 'quantity', null); // Establece null para "Sin límite"
              } else {
                handleChange(index, 'quantity', 1); // Establece un valor inicial para "Límite"
              }
            }}
          >
            {coverageType === 'acceptWithLimits' && (
              <Option value="excluir">Excluir</Option>
            )}
            <Option value="sin-limite">Sin límite</Option>
            <Option value="limite">Limitar</Option>
          </Select>
          {record.quantity !== null && (
            <InputNumber
              min={1}
              value={record.quantity}
              onChange={(value) => handleChange(index, 'quantity', value)}
            />
          )}
        </div>
      ),
    },
    {
      title: 'Días',
      dataIndex: 'days',
      key: 'days',
      width: '48%',
      render: (_, record, index) =>
        !record.exclusion && (
          <Checkbox.Group
            options={daysOfWeek}
            value={record.days}
            disabled={record.exclusion}
            onChange={(checkedValues) =>
              handleChange(index, 'days', checkedValues)
            }
          />
        ),
    },
    {
      key: 'actions',
      width: '2%',
      render: (_, __, index) => (
        <Tooltip title="Eliminar esta o.s/prepaga del listado">
          <Button type="link" onClick={() => handleDeleteRow(index)}>
            <MinusCircleOutlined />
          </Button>
        </Tooltip>
      ),
    },
  ];

  return (
    <div ref={tableRef}>
      <Alert
        message={
          <>
            Aquí puede configurar qué obras sociales o prepagas están
            habilitadas para solicitar turnos online.
            <br />
            Definir los días disponibles y establecer la cantidad máxima de
            turnos diarios para cada una
          </>
        }
        type="info"
        style={{
          marginBottom: 16,
          backgroundColor: '#f1f1f1',
          borderColor: '#cccccc',
          textAlign: 'center',
        }}
      />

      <Radio.Group
        onChange={(e) => {
          setCoverageType(e.target.value);
          e.target.value !== 'acceptAll' &&
            socialWorksConfig?.length === 0 &&
            handleAddRow();
        }}
        value={coverageType}
      >
        <Radio value="acceptAll">Aceptar todas</Radio>
        <Radio value="acceptWithLimits">
          Aceptar todas, con límites para las indicadas
        </Radio>
        <Radio value="onlyListed">Sólo las indicadas</Radio>
      </Radio.Group>

      {(coverageType === 'onlyListed' ||
        coverageType === 'acceptWithLimits') && (
        <Table
          dataSource={socialWorksConfig}
          columns={columns}
          rowKey={(record, index) => index}
          pagination={false}
          scroll={{ y: 300, x: 'auto' }}
        />
      )}

      <div style={{ textAlign: 'right', margin: '10px' }}>
        <Tooltip title="Agregar Obra Social">
          <Button
            type="default"
            shape="circle"
            onClick={handleAddRow}
            disabled={coverageType === 'acceptAll'}
          >
            <PlusOutlined />
          </Button>
        </Tooltip>
      </div>
      <Alert
        message={
          coverageType === 'acceptAll'
            ? 'Todas las obras sociales que crearon en el sistema estan habilitadas en su portal de turnos. '
            : coverageType === 'onlyListed'
            ? 'Sólo las obras sociales/prepagas que figuran en la tabla estan habilitadas en su portal de turnos.'
            : 'Todas las obras sociales que crearon en el sistema estan habilitadas en su portal de turnos. Y las que estan en tabla cuentan con dichas excepciones.'
        }
        type="success"
        showIcon
        style={{ marginBottom: 10, marginTop: 10 }}
      />

      <Button type="default" style={{ width: '100%' }} onClick={handleSave}>
        Guardar
      </Button>
    </div>
  );
};

export default SocialWorkConfigurationForm;
