import React, { useEffect } from 'react';
import {
  Form,
  Row,
  Col,
  Input,
  Button,
  notification,
  InputNumber,
  Radio,
} from 'antd';

import { getAccessTokenApi } from '../../api/auth';
import { addServiceApi, updateServiceApi } from '../../api/service';

export default function ServiceForm(props) {
  const { setIsVisibleModal, getService, data } = props;
  const [serviceForm] = Form.useForm();

  useEffect(() => {
    serviceForm.resetFields();
    serviceForm.setFieldsValue(data);
  }, [data]);

  const onFinish = async (values) => {
    try {
      let token = await getAccessTokenApi();
      data
        ? updateServiceApi(data?._id, values, token)
            .then((response) => {
              if (response?.code !== 200) {
                notification['error']({ message: response.message });
              } else {
                notification['success']({ message: response.message });
                getService();
                setIsVisibleModal(false);
                serviceForm.resetFields();
              }
            })
            .catch(() => {
              notification['error']({
                message: 'Error del servidor, intente mas tarde.',
              });
            })
        : addServiceApi(token, values)
            .then((response) => {
              if (response?.code !== 200) {
                notification['error']({ message: response.message });
              } else {
                notification['success']({ message: response.message });
                getService();
                setIsVisibleModal(false);
                serviceForm.resetFields();
              }
            })
            .catch(() => {
              notification['error']({
                message: 'Error del servidor, intente mas tarde.',
              });
            });
    } catch {
      notification['error']({
        message: 'Error del servidor, intente mas tarde.',
      });
    }
  };

  return (
    <div>
      <Form form={serviceForm} onFinish={onFinish}>
        <Row gutter={24}>
          <Col xs={24} md={12} lg={16}>
            <Form.Item
              name="name"
              rules={[{ required: true, message: 'Debe cargar una servicio.' }]}
              help={
                <Form.Item
                  name="public"
                  tooltip="Definir si el servicio es visible en portal de turnos o no."
                >
                  <Radio.Group defaultValue={true}>
                    <Radio value={false}>Privado</Radio>
                    <Radio value={true}>Publico</Radio>
                  </Radio.Group>
                </Form.Item>
              }
            >
              <Input placeholder="Nombre (*)" disabled={data} />
            </Form.Item>
          </Col>
          <Col xs={24} md={12} lg={8}>
            <Form.Item name="price">
              <Input
                prefix="$"
                type="number"
                inputMode="decimal"
                placeholder="Valor del servicio (opcional)"
              />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item
              name="description"
              help={
                <span style={{ fontWeight: 'bold' }}>
                  Esta descripción sera visible los pacientes luego de solicitar
                  su turno
                </span>
              }
            >
              <Input.TextArea
                maxLength={250}
                placeholder="Descripción visible por pacientes (opcional)"
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item>
              <Button
                type="default"
                htmlType="submit"
                style={{ width: '100%' }}
              >
                Guardar
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
}
