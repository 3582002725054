import React from 'react';
import moment from 'moment';
import './ViewInfo.scss';

export function ViewOperation(props) {
  const { data, income } = props;

  if (!data) {
    return null;
  }

  return (
    <div className="view-info">
      <h5>Identificación</h5>
      <h6 style={{ textTransform: 'capitalize' }}>
        {income?.name} - Obra social: {income?.socialWork}
      </h6>
      <h6>
        Fecha intervención: {moment(data?.date).format('DD/MM/YYYY')} - Hora
        inicio: {data.hourStart && moment(data?.hourStart).format('HH:mm')} hs -
        Hora fin: {data.hourFinish && moment(data?.hourFinish).format('HH:mm')}{' '}
        hs
      </h6>
      <h5>Personal interviniente</h5>
      <h6>
        Cirujano: Dr. {data?.surgeon} - Matricula: {data?.mpSurgeon}
      </h6>
      {data?.helpers?.map((helper, index) => (
        <h6>
          Ayudante {index + 1}: Dr. {helper.name} - Matricula: {helper.mp}
        </h6>
      ))}
      <h6>
        Anestesista: Dr. {data?.anesthetist} - Tipo anestesia:{' '}
        {data?.typeAnethetist} - Matricula: {data?.mpAnesthetist}
      </h6>
      <h6>
        Instrumentador/a: {data?.instrumenter} - Transfusión:{' '}
        {data?.transfusion} - Anatomia patologica: {data?.pathological}
      </h6>
      <h5>Datos medicos</h5>
      <h6>Diagnostico principal: {data?.principalDiagnostic}</h6>
      <h6>Diagnostico operatorio: {data?.operationDiagnostic}</h6>
      {data?.operationPractice && (
        <h6>Operación practicada: {data?.operationPractice}</h6>
      )}
      <h6>Uso de arco en C: {data?.arc}</h6>
      <h5>Descripción operatoria</h5>
      <p>{data?.description}</p>
    </div>
  );
}
