import React, { useEffect, useState } from 'react';
import {
  Row,
  Col,
  Form,
  DatePicker,
  Select,
  Input,
  Button,
  notification,
  Modal as ModalAntd,
  message,
} from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import locale from 'antd/es/date-picker/locale/es_ES';
import CityForm from '../../CityForm';
import SocialWorkForm from '../../SocialWorkForm';
import Modal from '../../Modal';
import {
  addPatientApi,
  updatePatientApi,
  deletePatientApi,
} from '../../../api/patient';
import { getAccessTokenApi } from '../../../api/auth';
import {
  onSocialWorkRead,
  onCityRead,
  onPatientFilterRead,
  onPatientFavouriteRead,
  onPatientValueChange,
} from '../../../actions';
import moment from 'moment';
import { ROLES, countryCodes } from '../../../utils/constants';
import PermissionsAsigner from '../../PermissionsAsigner';
import { DeleteOutlined } from '@ant-design/icons';

export default function PatientForm({
  setIsVisibleModal,
  patient,
  turnId,
  setReload,
}) {
  const [isVisibleModalForm, setIsVisibleModalForm] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalContent, setModalContent] = useState(null);
  const [loading, setLoading] = useState(false);
  const { cities } = useSelector((store) => store.cityData);
  const { socialWorks } = useSelector((store) => store.socialWorkData);

  const [patientForm] = Form.useForm();
  const dispatch = useDispatch();

  const { searchPatient } = useSelector((store) => store.patientData);

  const { role } = useSelector((store) => store.userData?.user);
  const { countryCode } = useSelector((store) => store.tenantData);

  useEffect(() => {
    dispatch(onCityRead());
    dispatch(onSocialWorkRead());
    patient &&
      patientForm.setFieldsValue({
        dni: patient.dni,
        name: patient.name,
        birthDate: patient.birthDate ? moment(patient.birthDate) : null,
        sex: patient.sex,
        city: patient?.city,
        socialWork: patient.socialWork,
        affiliate: patient?.affiliate,
        phone: patient?.phone,
        regionCode: patient?.notificationPhone?.regionCode,
        number: patient?.notificationPhone?.number,
        email: patient?.email,
        others: patient?.others,
      });
  }, [patient]);

  const addSocialWork = () => {
    setIsVisibleModalForm(true);
    setModalTitle('Cargando nueva obra social');
    setModalContent(
      <SocialWorkForm setIsVisibleModal={setIsVisibleModalForm} />
    );
  };

  const addCity = () => {
    setIsVisibleModalForm(true);
    setModalTitle('Cargando nueva ciudad');
    setModalContent(<CityForm setIsVisibleModal={setIsVisibleModalForm} />);
  };

  const onFinish = async (values) => {
    setLoading(true);

    values.notificationPhone = {
      countryCode: values?.countryCode,
      regionCode: values?.regionCode,
      number: values?.number,
    };

    if (turnId) {
      values.turnId = turnId;
      values.shiftId = patient._id;
    }

    const token = await getAccessTokenApi();
    patient && !turnId
      ? updatePatientApi(patient._id, values, token)
          .then((response) => {
            if (response?.code === 404) {
              notification['error']({ message: response.message });
              setLoading(false);
              ModalAntd.confirm({
                title: 'El dni ingresado ya tiene un paciente asociado.',
                content:
                  'No puede guardar dos pacientes con el mismo dni. Presione "Ir al Paciente" para ver los datos del mismo. En caso de no corresponder, para poder agregar este paciente presione "Cancelar" e ingrese un dni diferente.',
                okText: 'Ir al Paciente',
                okType: 'default',
                cancelText: 'Cancelar',
                onOk() {
                  dispatch(
                    onPatientValueChange({
                      searchPatient: values.dni.toString(),
                    })
                  );
                  setIsVisibleModal(false);
                },
              });
            } else if (response?.code !== 200) {
              notification['error']({ message: response.message });
              setLoading(false);
            } else {
              notification['success']({ message: response.message });
              setIsVisibleModal(false);
              dispatch(
                onPatientValueChange({
                  searchPatient: '',
                  searchFavourite: false,
                })
              );

              dispatch(
                onPatientValueChange({
                  searchPatient: values.dni.toString(),
                })
              );

              setLoading(false);
            }
          })
          .catch(() => {
            notification['error']({
              message: 'Error del servidor, intente mas tarde.',
            });
            setLoading(false);
          })
      : addPatientApi(values, token)
          .then((response) => {
            if (response?.code === 404) {
              notification['error']({ message: response.message });
              setLoading(false);
              ModalAntd.confirm({
                title: 'El dni ingresado ya tiene un paciente asociado.',
                content:
                  'No puede guardar dos pacientes con el mismo dni. Presione "Ir al Paciente" para ver los datos del mismo. En caso de no corresponder, para poder agregar este paciente presione "Cancelar" e ingrese un dni diferente.',
                okText: 'Ir al Paciente',
                okType: 'default',
                cancelText: 'Cancelar',
                onOk() {
                  dispatch(
                    onPatientValueChange({
                      searchPatient: values.dni.toString(),
                    })
                  );
                  setIsVisibleModal(false);
                },
              });
            } else if (response?.code !== 200) {
              notification['error']({ message: response.message });
              setLoading(false);
            } else {
              notification['success']({ message: response.message });
              dispatch(
                onPatientValueChange({
                  searchPatient: values.dni.toString(),
                  searchFavourite: false,
                })
              );
              patientForm.resetFields();
              if (turnId) {
                setReload(true);
              }
              setIsVisibleModal(false);
              setLoading(false);
            }
          })
          .catch(() => {
            notification['error']({
              message: 'Error del servidor, intente mas tarde.',
            });
            setLoading(false);
          });
  };

  async function showDeleteConfirm(patient) {
    const token = await getAccessTokenApi();

    ModalAntd.confirm({
      title: 'Eliminando Paciente',
      content: `Si elimina el paciente no podra acceder a ver su historia clinica y estudios asociados. Estas seguro que quieres eliminar a ${patient.name}?`,
      okText: 'Eliminar',
      okType: 'danger',
      cancelText: 'Cancelar',
      onOk() {
        deletePatientApi(token, patient._id, patient.dni)
          .then((result) => {
            notification['success']({ message: result.message });
            searchPatient !== false
              ? dispatch(
                  onPatientFilterRead({
                    searchPatient,
                  })
                )
              : dispatch(onPatientFavouriteRead());

            setIsVisibleModal(false);
          })
          .catch((err) => {
            notification['error']({ message: err });
          });
      },
    });
  }

  return (
    <div>
      <Form form={patientForm} onFinish={onFinish}>
        <Row gutter={24}>
          <Col xs={24} md={8}>
            <Form.Item
              name="dni"
              rules={[
                {
                  required: true,
                  message: 'Debe cargar el dni. Sin puntos',
                },
              ]}
              label={
                countryCode === '+54' ? 'DNI' : 'DNI / Cedula de Identidad'
              }
              labelCol={{ span: 24 }}
            >
              <Input
                placeholder="34256389"
                type="number"
                inputMode="numeric"
                disabled={
                  !patient
                    ? false
                    : patient?.hourStart ||
                      role === ROLES.OWNER.value ||
                      role === ROLES.SECRETARY.value
                    ? false
                    : true
                }
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={8}>
            <Form.Item
              name="name"
              rules={[
                {
                  required: true,
                  message: 'Debe cargar el Apellido y Nombre',
                },
              ]}
              label="Apellido y Nombre"
              labelCol={{ span: 24 }}
            >
              <Input
                placeholder="Apellido Nombre"
                style={{ textTransform: 'capitalize' }}
                disabled={
                  !patient
                    ? false
                    : patient?.hourStart ||
                      role === ROLES.OWNER.value ||
                      role === ROLES.SECRETARY.value
                    ? false
                    : true
                }
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={8}>
            <Form.Item
              name="email"
              label="Correo electronico"
              labelCol={{ span: 24 }}
              tooltip="Es necesario ingresar el mail del paciente, si luego se le quiere dar acceso a la Portal de Pacientes"
            >
              <Input placeholder="Correo electronico" inputMode="email" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col xs={24} md={8}>
            <Form.Item
              name="birthDate"
              label="Fecha de nacimiento"
              rules={[
                {
                  required: true,
                  message: 'Debe cargar fecha de nacimiento',
                },
              ]}
              labelCol={{ span: 24 }}
            >
              <DatePicker
                placeholder="DD-MM-AAAA"
                style={{ width: '100%' }}
                format="DD-MM-YYYY"
                locale={locale}
                showToday={false}
                onChange={(e) =>
                  e > moment().endOf('day')
                    ? message.warning('Fecha mayor a la actual')
                    : moment().diff(e, 'years') >= 110 &&
                      message.warning('Edad mayor a 110 años')
                }
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={8}>
            <Form.Item
              name="sex"
              rules={[{ required: true, message: 'Debe cargar el sexo' }]}
              label="Sexo"
              labelCol={{ span: 24 }}
            >
              <Select placeholder="Sexo (*)" inputMode="none">
                <Select.Option value="Masculino">Masculino</Select.Option>
                <Select.Option value="Femenino">Femenino</Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} md={8}>
            <Form.Item
              name="city"
              label="Ciudad de origen"
              labelCol={{ span: 24 }}
            >
              <Select
                placeholder="Seleccione una ciudad"
                showSearch
                optionFilterProp="children"
                style={{ textTransform: 'capitalize' }}
              >
                <Select.Option disabled>
                  <Button type="link" onClick={addCity}>
                    + Nueva ciudad
                  </Button>
                </Select.Option>
                {cities &&
                  cities.map((city, index) => (
                    <Select.Option
                      key={index}
                      value={city.name}
                      style={{ textTransform: 'capitalize' }}
                    >
                      {city.name}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col xs={24} md={8}>
            <Form.Item
              name="socialWork"
              rules={[
                {
                  required: true,
                  message: 'Debe cargar la obra social / seguro',
                },
              ]}
              label="Obra social / Seguro"
              labelCol={{ span: 24 }}
            >
              <Select
                placeholder="Obra Social "
                showSearch
                optionFilterProp="children"
                style={{ textTransform: 'uppercase' }}
              >
                <Select.Option disabled>
                  <Button type="link" onClick={addSocialWork}>
                    + Nueva obra social
                  </Button>
                </Select.Option>
                {socialWorks &&
                  socialWorks.map((socialWork, index) => (
                    <Select.Option
                      key={index}
                      value={socialWork.name}
                      style={{ textTransform: 'uppercase' }}
                    >
                      {socialWork.name}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} md={8}>
            <Form.Item
              name="affiliate"
              label="Nro de afiliado"
              labelCol={{ span: 24 }}
            >
              <Input placeholder="Nro de afiliado" />
            </Form.Item>
          </Col>
          <Col xs={24} md={8}>
            <Form.Item
              label="Teléfono de contacto"
              labelCol={{ span: 24 }}
              tooltip="En caso de contar con recordatorio de turnos automatico, es muy importante tener el nro de telefono en el formato correcto"
            >
              <Row>
                <Col xs={12} xxl={6}>
                  <Form.Item
                    name="countryCode"
                    noStyle
                    initialValue={
                      patient?.notificationPhone?.countryCode || countryCode
                    }
                  >
                    <Select style={{ fontSize: '8pt' }}>
                      {countryCodes?.map((country) => (
                        <Select.Option value={country.dial_code}>
                          {country.emoji}
                          {country.dial_code}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                {countryCode === '+54' && (
                  <Col xs={12} xxl={6}>
                    <Form.Item
                      name="regionCode"
                      noStyle
                      initialValue={patient?.notificationPhone?.regionCode}
                    >
                      <Input
                        placeholder="Cod Area"
                        help="385"
                        maxLength={4}
                        type="number"
                        inputMode="tel"
                      />
                    </Form.Item>
                  </Col>
                )}
                <Col xs={24} xxl={12}>
                  <Form.Item
                    name="number"
                    noStyle
                    initialValue={patient?.notificationPhone?.number}
                  >
                    <Input
                      addonBefore={
                        patientForm.getFieldsValue()?.countryCode === '+54' &&
                        '15'
                      }
                      placeholder="Nro Tel."
                      maxLength={
                        patientForm.getFieldsValue()?.countryCode === '+54' && 7
                      }
                      type="number"
                      inputMode="tel"
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Form.Item>
            {!patient?.notificationPhone?.number && patient?.phone && (
              <Form.Item name="phone" help="Teléfono formato viejo">
                <Input disabled={true} />
              </Form.Item>
            )}
          </Col>
        </Row>

        <Row>
          <Col xs={24}>
            <Form.Item name="others" label="Otros" labelCol={{ span: 24 }}>
              <Input.TextArea
                placeholder="Campo para anotar datos de interes relacionado al paicentes. Ej. direccion, equipamiento que se llevo en caso de estudio invasivo, o lo que considere necesario"
                autoSize={{ minRows: 2 }}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={24} style={{ textAlign: 'center' }}>
          <Col span={24}>
            <Form.Item>
              <Button
                type="default"
                htmlType="submit"
                style={{ width: '100%', marginTop: '10px' }}
                disabled={loading}
                loading={loading}
              >
                {patient && !turnId ? 'Actualizar' : 'Guardar Nuevo Paciente'}
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      {patient && !turnId && (
        <Row gutter={24} style={{ textAlign: 'center' }}>
          <Col span={24}>
            <PermissionsAsigner requiredRole={ROLES.OWNER}>
              <Button
                type="danger"
                style={{ width: '100%' }}
                onClick={() => showDeleteConfirm(patient)}
              >
                Eliminar Paciente <DeleteOutlined />
              </Button>
            </PermissionsAsigner>
          </Col>
        </Row>
      )}

      <Modal
        title={modalTitle}
        isVisible={isVisibleModalForm}
        setIsVisible={setIsVisibleModalForm}
        width={'50%'}
      >
        {modalContent}
      </Modal>
    </div>
  );
}
