import React from 'react';
import moment from 'moment';
import './ViewInfo.scss';

export function ViewInfo(props) {
  const { data } = props;

  if (!data) {
    return null;
  }

  return (
    <div className="view-info">
      <h4>{moment(data?.date).format('DD/MM/YYYY HH:mm')}</h4>
      <h4>{data?.title}</h4>
      <p>{data?.description}</p>
      <div className="view-info__signature">
        <h4>
          {data?.user?.name} {data?.user?.lastname}
        </h4>
        {data?.user?.licenseType && (
          <div>
            <p>{data?.user?.type}</p>
            <p>
              {data?.user?.medicalSpeciality &&
                `ESP. en ${data?.user?.medicalSpeciality}`}
            </p>
            <p>
              {data?.user?.mp &&
                `${data?.user?.licenseType === 'nacional' ? 'MN' : 'MP'} ${
                  data?.user?.mp
                }`}
            </p>
          </div>
        )}
      </div>
    </div>
  );
}
