import React, { useState, useEffect } from 'react';
import { Button, Tooltip, PageHeader } from 'antd';
import Modal from '../../../components/Modal';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { getAccessTokenApi } from '../../../api/auth';
import NotesForm from '../../../components/Infarmary/Notes';
import { getNoteApi } from '../../../api/note';
import {
  ListDates,
  ViewInfo,
  PageListAndView,
} from '../../../components/ListAndDetail';
import {
  ControlOutlined,
  DiffOutlined,
  PullRequestOutlined,
} from '@ant-design/icons';
import { Link } from 'react-router-dom';

export default function Notes(props) {
  const { id, history } = props.match.params;
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalContent, setModalContent] = useState(null);
  const [data, setData] = useState(null);
  const [dataAll, setDataAll] = useState(null);
  const [notes, setNotes] = useState();
  const [reload, setReload] = useState(false);
  const [order, setOrder] = useState('desc');

  const { incomesActive, incomesHistory } = useSelector(
    (store) => store.incomeData
  );
  const { type } = useSelector((store) => store.userData?.user);

  useEffect(() => {
    if (type === 'Medico' && !history) props.history.push('/');

    const getData = async () => {
      const token = await getAccessTokenApi();
      getNoteApi(id, order, token)
        .then((response) => {
          setNotes(response.notes);
          setData({
            description: response.notes[0].description,
            user: response.notes[0].user,
            date: response.notes[0].date,
          });
        })
        .catch((err) => {});

      setReload(false);
    };
    getData();
  }, [reload, order]);

  const viewNote = (note) => {
    setData({
      description: note.description,
      user: note.user,
      date: note.date,
    });
    window.scroll({
      top: '500',
      behavior: 'instant',
    });
  };

  const addNote = (income) => {
    setIsVisibleModal(true);
    setModalTitle(`Agregar nota de enfermeria a ${income.name}`);
    setModalContent(
      <NotesForm
        incomeId={id}
        setIsVisibleModal={setIsVisibleModal}
        setReload={setReload}
        discharge={income.dischargeDate}
      />
    );
  };

  var income = {};
  history
    ? incomesHistory.find((i) => {
        if (i._id === id) {
          income = i;
        }
      })
    : incomesActive.find((i) => {
        if (i._id === id) {
          income = i;
        }
      });

  const viewAll = () => {
    const data = [];

    notes &&
      notes.map((note) =>
        data.push({
          description: note.description,
          user: note.user,
          date: note.date,
        })
      );
    setData(null);
    setDataAll(data);
    window.scroll({
      top: '500',
      behavior: 'instant',
    });
  };

  return (
    <div className="evolution">
      <div className="evolution-header">
        <PageHeader
          ghost={false}
          onBack={() => window.history.back()}
          title={`Cama: ${income.bed.name}`}
          subTitle={
            <Tooltip
              title={`Cama: ${income.bed.name} ${income.name} - ${
                income.age
              } años | Fecha Ingreso: ${moment(income.date).format(
                'DD/MM/YYYY'
              )}`}
            >
              {income.name} - {income.age} años | Fecha Ingreso:{' '}
              {moment(income.date).format('DD/MM/YYYY')}
            </Tooltip>
          }
          extra={
            <>
              {!history && (
                <>
                  <Tooltip title="Controles">
                    <Link
                      to={
                        income.bed?.bedType !== 'Dialisis'
                          ? `/controls/${income._id}`
                          : `/dialisis-controls/${income._id}`
                      }
                    >
                      <Button type="link">
                        <ControlOutlined />
                      </Button>
                    </Link>
                  </Tooltip>

                  {income.bed?.bedType === 'Dialisis' ? (
                    <Tooltip title="Insumos">
                      <Link to={`/insumos/${income._id}`}>
                        <Button type="link">
                          <PullRequestOutlined />
                        </Button>
                      </Link>
                    </Tooltip>
                  ) : (
                    <Tooltip title="Balances">
                      <Link to={`/balances/${income._id}`}>
                        <Button type="link">
                          <DiffOutlined />
                        </Button>
                      </Link>
                    </Tooltip>
                  )}
                </>
              )}
              <Button type="primary" onClick={() => addNote(income)}>
                Agregar Nota
              </Button>
            </>
          }
        />
      </div>

      <PageListAndView
        datesTitle="Notas de enfermería"
        order={order}
        setOrder={setOrder}
        viewAll={viewAll}
        componentDate={<ListDates data={notes} viewData={viewNote} />}
        detailTitle="Detalle notas"
        data={data}
        dataAll={
          dataAll && (
            <div className="view-info">
              {dataAll?.map((data, index) => (
                <>
                  <ViewInfo data={data} key={index} />
                </>
              ))}
            </div>
          )
        }
        componentDetail={<ViewInfo data={data} />}
      />
      <Modal
        title={modalTitle}
        isVisible={isVisibleModal}
        setIsVisible={setIsVisibleModal}
        width={window.screen.width < 500 ? '80%' : '50%'}
      >
        {modalContent}
      </Modal>
    </div>
  );
}
