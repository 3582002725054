import React, { useState } from 'react';
import { Button, Collapse, Tag, Tooltip } from 'antd';
import Spin from '../../Spin';
import moment from 'moment';
import Modal from '../../Modal';
import './PatientList.scss';
import { Link } from 'react-router-dom';
import PatientForm from '../PatientForm';
import {
  EditOutlined,
  StarFilled,
  InfoCircleOutlined,
  CheckOutlined,
  WhatsAppOutlined,
} from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { onAddPatientFavourite } from '../../../actions';
import PatientDescription from '../PatientDescription';
import { ROLES } from '../../../utils/constants';

export default function PatientList({
  patient,
  turn,
  addTurn,
  turnId,
  isAddingTurn,
}) {
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalContent, setModalContent] = useState(null);

  const dispatch = useDispatch();
  const { favouritePatients, role, modulesAccess } = useSelector(
    (store) => store.userData?.user
  );

  const { patients, searchPatient, loading } = useSelector(
    (store) => store.patientData
  );
  const { tenantTimezone } = useSelector((store) => store.tenantData);

  const editPatient = (patient) => {
    setIsVisibleModal(true);
    setModalTitle('Editar Paciente');
    setModalContent(
      <PatientForm setIsVisibleModal={setIsVisibleModal} patient={patient} />
    );
  };

  if (loading) return <Spin />;

  let textUrlEncode = turn
    ? `%2A%C2%A1%20Hola%20%20${
        patient.name
      }%20%21%2A%0A%0ATe%20recordamos%20que%20tienes%20un%20turno%20para%20el%20${moment(
        turn.hourStart
      )
        .tz(tenantTimezone)
        .format('DD/MM HH:mm')}hs`
    : `%2A%C2%A1%20Hola%20%20${patient.name}%20%21%2A`;

  let urlHc = `/ambulatory-consultations/${patient._id}/${patient.name}`;

  if (turnId && turn?._id) {
    urlHc += `?turnId=${turnId}&shiftId=${turn._id}&turnDay=${turn.hourStart}`;
  }

  return (
    <>
      <Collapse
        className="card-patient"
        defaultActiveKey={patients.length < 4 && 1}
      >
        <Collapse.Panel
          collapsible="header"
          showArrow={false}
          header={
            <Tooltip title="Presione para ver/ocultar detalle">
              {patient.name} ({moment().diff(patient.birthDate, 'years')} años)
            </Tooltip>
          }
          key={1}
          extra={
            <div className="card-patient_extra">
              {(role !== ROLES.SECRETARY.value ||
                modulesAccess?.includes('Ver h.c amb')) && (
                <Link to={urlHc}>
                  <Tooltip title="Informacion Paciente">
                    <Button type="link" style={{ color: '#fff' }}>
                      <InfoCircleOutlined />
                    </Button>
                  </Tooltip>
                </Link>
              )}
              {patient?.notificationPhone?.number && (
                <Tooltip title="Enviar WhatsApp">
                  <Button type="link">
                    <a
                      href={`https://wa.me/${
                        patient?.notificationPhone?.countryCode
                      }${patient?.notificationPhone?.regionCode || ''}${
                        patient?.notificationPhone?.number
                      }?text=${textUrlEncode}`}
                      target="_blank"
                      referrerPolicy="origin"
                    >
                      <WhatsAppOutlined style={{ color: '#fff' }} />
                    </a>
                  </Button>
                </Tooltip>
              )}

              <Tooltip title="Favorito">
                <Button
                  type="link"
                  style={{ color: '#fff' }}
                  onClick={() => {
                    dispatch(
                      onAddPatientFavourite(
                        patient._id,
                        favouritePatients.find((i) => i === patient._id)
                          ? 'delete'
                          : favouritePatients?.length < 1000
                          ? 'add'
                          : 'maxLimit',
                        searchPatient
                      )
                    );
                  }}
                >
                  <StarFilled
                    style={
                      favouritePatients.find((i) => i === patient._id) && {
                        color: 'yellow',
                      }
                    }
                  />
                </Button>
              </Tooltip>

              <>
                <Tooltip title="Editar">
                  <Button
                    style={{ color: '#fff' }}
                    type="link"
                    onClick={() => editPatient(patient)}
                  >
                    <EditOutlined />
                  </Button>
                </Tooltip>
                {turn && !turn?.patient && (
                  <Tooltip title="Asignar Turno">
                    <Button
                      type="link"
                      style={{ color: '#fff' }}
                      onClick={async () => await addTurn(patient)}
                      disabled={isAddingTurn}
                    >
                      <CheckOutlined />
                    </Button>
                  </Tooltip>
                )}
              </>
            </div>
          }
        >
          <PatientDescription patient={patient} urlHc={urlHc} />
        </Collapse.Panel>
      </Collapse>
      <Modal
        title={modalTitle}
        isVisible={isVisibleModal}
        setIsVisible={setIsVisibleModal}
        width={'80%'}
      >
        {modalContent}
      </Modal>
    </>
  );
}
