import { StyleSheet, Font } from '@react-pdf/renderer';

Font.register({
  family: 'Font Bold',
  src: 'https://kendo.cdn.telerik.com/2017.2.621/styles/fonts/DejaVu/DejaVuSans-Bold.ttf',
});

Font.register({
  family: 'Font Normal',
  src: 'https://kendo.cdn.telerik.com/2017.2.621/styles/fonts/DejaVu/DejaVuSans.ttf',
});

export const styleBody = StyleSheet.create({
  body: {
    paddingTop: 5,
    paddingHorizontal: '25mm',
    paddingBottom: '30mm',
  },
  bodyWithoutHeader: {
    paddingHorizontal: '25mm',
    paddingBottom: '25mm',
    paddingTop: '15mm',
  },
  title: {
    fontSize: '14pt',
    textAlign: 'center',
    fontFamily: 'Font Bold',
    paddingBottom: 5,
  },
  subtitle: {
    marginTop: 5,
    fontSize: '12pt',
    textAlign: 'center',
    marginBottom: 10,
    fontFamily: 'Font Bold',
    textTransform: 'capitalize',
  },
  textTitle: {
    fontSize: '11pt',
    fontFamily: 'Font Bold',
    paddingRight: 2,
  },
  text: {
    fontSize: '11pt',
    textAlign: 'justify',
    fontFamily: 'Font Normal',
  },
  textUppercase: {
    fontSize: '11pt',
    textTransform: 'uppercase',
    textAlign: 'justify',
    fontFamily: 'Font Normal',
  },
  textCapitalize: {
    fontSize: '11pt',
    textTransform: 'capitalize',
    textAlign: 'justify',
    fontFamily: 'Font Normal',
  },
  textContact: {
    fontSize: '11pt',
    textAlign: 'center',
    fontFamily: 'Font Normal',
  },
  textSuspend: {
    fontSize: '11pt',
    textAlign: 'justify',
    fontFamily: 'Font Normal',
    textDecoration: 'line-through',
  },
  image: {
    marginVertical: 15,
    marginHorizontal: 100,
  },
  data: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 5,
  },
  dataEvolution: {
    display: 'flex',
    marginBottom: 5,
  },
  evolutionTitle: {
    fontSize: '11pt',
    fontFamily: 'Font Bold',
    paddingRight: 2,
    paddingBottom: 5,
  },
  signature: {
    textAlign: 'right',
    paddingTop: 2,
  },
  signatureName: {
    fontSize: '9pt',
    fontFamily: 'Font Bold',
    textAlign: 'right',
  },
  signatureDescription: {
    fontSize: '8pt',
  },
  signatureImage: {
    width: '20%',
    alignSelf: 'flex-end',
  },
  personalData: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 5,
  },
  pageNumber: {
    marginTop: 10,
    position: 'absolute',
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: 'grey',
  },
  pageFooter: {
    marginTop: 10,
    position: 'absolute',
    fontSize: 12,
    bottom: 0,
    left: 0,
    right: 0,
    textAlign: 'center',
  },

  watermarkText: {
    fontSize: 8,
    color: 'grey', // Gris claro
    marginTop: 5,
    textAlign: 'center',
  },

  signatureImageFooter: {
    marginLeft: '20mm',
    width: '80%',
    height: 55,
    bottom: 15,
    alignSelf: 'flex-start',
  },

  signatureContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 20,
    marginBottom: 10,
  },
  signatureLeft: {
    width: '35%',
    textAlign: 'left',
  },
  signatureRight: {
    width: '35%',
    textAlign: 'right',
  },
  signatureText: {
    fontSize: 12,
    fontWeight: 'bold',
    marginBottom: 30,
  },
  signatureLine: {
    height: 1, // Tamaño de la línea
    backgroundColor: '#000', // Color negro para la línea
    borderStyle: 'dotted', // Punteado
    marginBottom: 10,
  },
  signatureNote: {
    fontSize: 10,
    color: '#888',
  },
});

export const styleHeader = StyleSheet.create({
  header: {
    marginBottom: 20,
    marginTop: 10,
    display: 'flex',
    height: 61,
    borderBottomWidth: 1,
  },
  headerText: {
    paddingTop: 5,
    fontSize: 12,
    left: 0,
  },
  imageHeader: {
    width: '19%',
    position: 'absolute',
    justifyContent: 'flex-end',
    right: 0,
    maxHeight: 60,
    objectFit: 'contain',
  },
});

export const styleTable = StyleSheet.create({
  row: {
    flexDirection: 'row',
    alignItems: 'center',
    fontStyle: 'bold',
    display: 'flex',
    borderBottomWidth: 1,
    borderRightWidth: 1,
    borderLeftWidth: 1,
    minHeight: 30,
    maxHeight: 50,
  },
  rowDyalisisData: {
    flexDirection: 'row',
    alignItems: 'center',
    fontStyle: 'bold',
    display: 'flex',
    borderBottomWidth: 1,
    borderRightWidth: 1,
    borderLeftWidth: 1,
    maxHeight: 30,
  },
  header: {
    backgroundColor: '#002a52',
    color: '#eef0f4',
  },
  column1: {
    width: '13%',
    textAlign: 'center',
    display: 'flex',
    padding: 5,
    fontSize: 9,
  },
  column2: {
    width: '22%',
    textAlign: 'center',
    padding: 5,
    fontSize: 9,
  },
  column3: {
    width: '10%',
    textAlign: 'center',
    padding: 3,
    fontSize: 9,
  },
  column4: {
    width: '70%',
    textAlign: 'center',
    padding: 5,
    fontSize: 9,
  },
  column5: {
    width: '30%',
    textAlign: 'center',
    padding: 5,
    fontSize: 9,
  },
  column6: {
    width: '15%',
    maxWidth: '15%',
    textAlign: 'center',
    display: 'flex',
    padding: 5,
    fontSize: 9,
  },
  column7: {
    width: '8%',
    textAlign: 'center',
    padding: 3,
    fontSize: 9,
  },
  column8: {
    width: '4%',
    textAlign: 'center',
    padding: 3,
    fontSize: 9,
  },
});
